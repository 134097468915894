import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineSwapRight
} from 'react-icons/ai';
import PollThumbnail from '../ones/PollThumbnail';
import { callApi, getInlineLoader } from "../Helpers";
import YouTube from 'react-youtube';


export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ openPolls, setOpenPolls ] = useState([]);

  async function getOpenPolls() {
    await callApi('get_open_polls.php', { }).then(response => {
      if(response.status === 1) {
        setOpenPolls(response.data);
      } 
    })
  }

  async function init() {
    setReady(false);
    await getOpenPolls();
    setReady(true);
  } 

  useEffect(() => {
    init();
  }, [ ])


  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row" >
          <div className="col-md-12">
            <h1 className="font-bold">NyikaTimes</h1>
            <h3 className="font-light text-muted">Our YouTube channel for Sports News & Updates</h3>
          </div>

          <div className="col-md-12">
            <YouTube
              videoId="90Q3SlxMdDA"
              opts={{
                width: "100%",
                height: "360px"
              }}
            />
          </div>

          <div className="col-md-12 text-end">
            <a
              className="btn btn-sm text-dark font-bold"
              href="https://www.youtube.com/@runtanzania"
              target="_blank"
            >
              Subscribe to NyikaTimes on YouTube <AiOutlineSwapRight size={20}/>
            </a>
          </div>
        </div>
        
      </div>
      
    </div>

    
  )
}