import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { MdDashboard, MdDarkMode, MdOutlineDarkMode } from 'react-icons/md';

export default function MainBody (props) {

  const appContext = useContext(AppContext);

  return (
    <div className="MainBody">
      {appContext.mainView}
    </div>
  );
}