import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdSearch,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineSwapRight
} from 'react-icons/ai';
import PollThumbnail from '../ones/PollThumbnail';
import { BASE_API, callApi, getInlineLoader } from "../Helpers";


export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ allItems, setAllItems ] = useState([]);
  const [ filter, setFilter ] = useState("");
  const [  scrollPosition, setScrollPosition ] = useState(0);


  async function getAllItems() {
    await callApi('get_marketplace_items.php', { }).then(response => {
      if(response.status === 1) {
        setAllItems(response.data);
      } 
    })
  }

  async function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getAllItems();
    setReady(true);
  } 

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(scrollPosition > 0) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])


  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row" >
          <div className="col-md-12">
            <h1 className="font-bold">Marketplace</h1>
          </div>

          <div className="col-md-12 d-flex" style={{ marginBottom: "20px" }}>
            <MdSearch size={30} className="mNoMargin mNoPad align-self-center" style={{ flexShrink: "0" }}/>
            <input
              className="form-control align-self-center"
              placeholder="Filter by item title"
              style={{
                marginLeft: "20px"
              }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>

          {
            (ready) ?
            <>
              {
                (allItems && allItems.length > 0) ?
                allItems.map((item, i) => {
                  let view = () => appContext.navTo({
                    item: 'view',
                    subItem: 'marketplace',
                    extraItem: item.uid
                  });

                  if(filter && filter.trim().length > 0) {
                    if(String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
                      return (
                        <div className="col-md-4" style={{ marginBottom: "20px", marginTop: "20px" }} key={i}>
                          <div
                            className="mShadow3"
                            style={{
                              width: "100%",
                              height: "200px",
                              overflow: "hidden",
                              borderRadius: "20px",
                              cursor: "pointer",
                            }}
                            onClick={view}
                          >
                            <img
                              src={BASE_API+item.coverPhoto}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <h5 onClick={view} style={{ marginTop: "10px", cursor: "pointer" }} className="font-medium">{item.title}</h5>
                        </div>
                      )
                    }
                  } else {
                    return (
                      <div className="col-md-4" style={{ marginBottom: "20px", marginTop: "20px" }} key={i}>
                        <div
                          className="mShadow3"
                          style={{
                            width: "100%",
                            height: "200px",
                            overflow: "hidden",
                            borderRadius: "20px",
                            cursor: "pointer",
                          }}
                          onClick={view}
                        >
                          <img
                            src={BASE_API+item.coverPhoto}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <h5 onClick={view} style={{ marginTop: "10px", cursor: "pointer" }} className="font-medium">{item.title}</h5>
                      </div>
                    )
                  }

                  
                })
                :
                <h6 className="font-light">No items were found</h6>
              }
            </>
            :
            <div className="col-md-12 mSupportLoading">
              {getInlineLoader()}
            </div>
          }

          
        </div>
        
      </div>
      
    </div>

    
  )
}