import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdSearch,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineSwapRight
} from 'react-icons/ai';
import EventThumbnail from '../ones/EventThumbnail';
import { callApi, getInlineLoader } from "../Helpers";


export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ allEvents, setAllEvents ] = useState([]);
  const [ filter, setFilter ] = useState("");
  const [  scrollPosition, setScrollPosition ] = useState(0);

  async function getAllEvents() {
    await callApi('get_all_events.php', { }).then(response => {
      if(response.status === 1) {
        setAllEvents(response.data);
      } 
    })
  }

  async function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getAllEvents();
    setReady(true);

  } 

  useEffect(() => {
    init();

    return () => {
      //appContext.restoreScroll(scrollPosition);
      console.log("All events destroyed");
    }
  }, [ ])

  useEffect(() => {
    if(scrollPosition > 0) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])


  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row" >
          <div className="col-md-12">
            <h1 className="font-bold">Events</h1>
          </div>

          <div className="col-md-12 d-flex" style={{ marginBottom: "20px" }}>
            <MdSearch size={30} className="mNoMargin mNoPad align-self-center" style={{ flexShrink: "0" }}/>
            <input
              className="form-control align-self-center"
              placeholder="Filter by event title"
              style={{
                marginLeft: "20px"
              }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>

          {
            (ready) ?
            <>
              {
                (allEvents && allEvents.length > 0) ?
                allEvents.map((item, i) => {
                  if(filter && filter.trim().length > 0) {
                    if(String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
                      return (
                        <EventThumbnail supportCol={true} data={item} key={i}/>
                      )
                    }
                  } else {
                    return (
                      <EventThumbnail supportCol={true} data={item} key={i}/>
                    )
                  }

                  
                })
                :
                <h6 className="font-light">No events were found</h6>
              }
            </>
            :
            <div className="col-md-12 mSupportLoading">
              {getInlineLoader()}
            </div>
          }

          
        </div>
        
      </div>
      
    </div>

    
  )
}