import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import mymiles0 from '../assets/mymiles0.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineApple,
  AiOutlineAndroid
} from 'react-icons/ai';
import { IoLogoGooglePlaystore } from 'react-icons/io5';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [  scrollPosition, setScrollPosition ] = useState(0);

  function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(scrollPosition > 0 && !props.isHome) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row">
          <div className="col-md-12">
            <h1 className="font-bold">MyMiles App</h1>
            <h3 className="font-light text-muted">Fitness Tracking App by RUNTanzania&reg; Initiative</h3>
          </div>

          <div className="col-md-4">
            <img src={mymiles0} width="100%" height="auto"/>
          </div>

          <div className="col-md-8">
            <h5 style={{ marginTop: "40px" }} className="font-bold">Features</h5>
            <ul>
              <li style={{ fontSize: "25px" }}>Track your data as you run, jog or walk</li>
              <li style={{ fontSize: "25px" }}>Easily share your fitness data</li>
              <li style={{ fontSize: "25px" }}>Participate in virtual jogging competitions</li>
              <li style={{ fontSize: "25px" }}>Easily buy tickets for events</li>
              <li style={{ fontSize: "25px" }}>Directly Access RUNTanzania.org Portal</li>
            </ul>

            <h5 style={{ marginTop: "40px" }} className="font-bold">Download Now!</h5>

            <div className="row">
              <div className="col-6">
                <button
                  className="btn bg-primary btn-block"
                  style={{
                    fontSize: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <AiOutlineAndroid size={40}/><br/>
                  For Android
                </button>

              </div>

              <div className="col-6">
                <button
                  className="btn bg-primary btn-block"
                  style={{
                    fontSize: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <AiOutlineApple size={40}/><br/>
                  For iOS
                </button>
                
              </div>
            </div>
          </div>
        </div>

        
      </div>
      
    </div>

    
  )
}