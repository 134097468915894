import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';
import { BASE_API, formatDate } from "../Helpers";

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ data, setData ] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])

  function view() {
    appContext.navTo({
      item: 'view',
      subItem: 'event',
      extraItem: data.uid,
    })
  }

  if(props.supportCol) {
    return (
      <div className="col-md-4">
        <div className="EventThumbnail" data-support-col={props.supportCol}>
          <div className="mDate text-center">
            <span>{(new Date(data.startDate * 1000)).toString().substring(0,15)}</span>
          </div>
          <div className="mCover" onClick={view}>
            <img src={BASE_API+data.coverPhoto}/>
          </div>
          <div className="mTitle">
            <span onClick={view} style={{ cursor: "pointer" }}>{data.title}</span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="EventThumbnail" data-support-col={props.supportCol}>
        <div className="mDate text-center">
          <span>{(new Date(data.startDate * 1000)).toString().substring(0,15)}</span>
        </div>
        <div className="mCover" onClick={view}>
          <img src={BASE_API+data.coverPhoto}/>
        </div>
        <div className="mTitle">
          <span onClick={view} style={{ cursor: "pointer" }}>{data.title}</span>
        </div>
      </div>
    )
  }
}