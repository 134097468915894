import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import ReactToPrint from "react-to-print";
import { 
  MdOutlineEventNote,
  MdOutlineShare,
  MdPrint
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';
import { IoTicketOutline } from 'react-icons/io5';
import { BASE_API, callApi, copyText, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import QRCode from "react-qr-code";

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ data, setData ] = useState(null);
  const [ id, setId ] = useState(props.id);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [  scrollPosition, setScrollPosition ] = useState(0);
  const componentRef = useRef();


  async function getTicketData() {
    await callApi("get_ticket_data.php", { ticketId: id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  

  async function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getTicketData();
    setReady(true);
  }

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(scrollPosition > 0) {
      //appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  if(ready) {
    if(data) {
      return (
        <div className="Section bg-background">
          <div className="container">
            <div className="row">
            {
              (appContext.auth && appContext.userData && appContext.userData.id === data.userId) ?
              <>
                <div 
                  className="col-md-12"
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px"
                  }}
                  
                >
                  <div ref={componentRef} className="" style={{ maxWidth: "400px", padding: "10px", margin: "0 auto", border: "2px dotted black", }}>
                    
                    <div 
                      className="mShadow3"
                      style={{
                        width: "300px",
                        padding: "10px",
                        margin: "0 auto",
                      }}
                    >
                      <div className="d-flex justify-content-between" style={{ width: "100%", overflowX: "hidden" }}>
                        <img
                          src={logo}
                          className=""
                          height="60px"
                          width="auto"
                          style={{
                            filter: "grayscale(100%) brightness(0)",
                            flexShrink: "0",
                          }}
                        />
                        <img
                          src={logo}
                          className=""
                          height="60px"
                          width="auto"
                          style={{
                            filter: "grayscale(100%) brightness(0)",
                            flexShrink: "0",
                          }}
                        />
                        <img
                          src={logo}
                          className=""
                          height="60px"
                          width="auto"
                          style={{
                            filter: "grayscale(100%) brightness(0)",
                            flexShrink: "0",
                          }}
                        />
                      </div>

                      <div className="text-center">
                        <hr/>
                        <h6 style={{ fontSize: "12px" }} className="font-bold text-muted">TICKET TYPE</h6>
                        <h3 className="font-bold">{data.name}</h3>
                        <h1 className="font-light" style={{ marginTop: "20px", fontSize: "35px" }}>{formatMoney(data.price)} <span style={{ fontSize: "20px" }}> Tsh</span></h1>
                      </div>

                      <div className="text-center">
                        <hr/>
                        <h6 style={{ fontSize: "12px" }} className="font-bold text-muted">EVENT NAME</h6>
                        <h3 className="font-bold">{data.eventData.title}</h3>
                      </div>

                      <div className="text-center" style={{  marginTop: "20px" }}>
                        
                        <h6 style={{ fontSize: "12px" }} className="font-bold text-muted">EVENT DATE</h6>
                        {
                          ((new Date(data.eventData.startDate * 1000)).toString().substring(0,15) === (new Date(data.eventData.endDate * 1000)).toString().substring(0,15)) ?
                          <h6>{(new Date(data.eventData.startDate * 1000)).toString().substring(0,15)}</h6>
                          :
                          <h6><span className="font-bold">From </span>{(new Date(data.eventData.startDate * 1000)).toString().substring(0,15)} <span className="font-bold">to</span> {(new Date(data.eventData.endDate * 1000)).toString().substring(0,15)}</h6>
                        }
                      </div>

                      <div className="text-center">
                        <hr/>
                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                          <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={id}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                        <h6 style={{ fontSize: "12px", marginTop: "10px" }} className="font-bold">{id}</h6>

                      </div>

                      <div className="text-end">
                        <h6 style={{ fontSize: "12px", marginTop: "40px" }} className="font-medium text-muted">Created on <br/> {data.date}</h6>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="col-md-12 text-center" style={{ marginTop: "20px" }}>
                  <ReactToPrint
                    trigger={() => <button className="btn bg-primary"><MdPrint size={20}/> <br/> PRINT</button>}
                    content={() => componentRef.current}
                  />
                  
                </div>
              </>
              :
              <div className="col-md-12">
                <h6>You do not have access to view this ticket</h6>
              </div>
            }  
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="Section bg-background">
          <div className="mSupportLoading" style={{ width: "100%" }}>
            {getInlineLoader()}
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className="Section bg-background">
        <div className="mSupportLoading" style={{ width: "100%" }}>
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}