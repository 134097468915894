import { useContext, useState, useRef, useEffect } from "react";
import { AppContext } from "../App";
import {
  MdPostAdd
} from 'react-icons/md';
import { getInlineLoader, BASE_API, callApi } from "../Helpers";
import { Editor } from '@tinymce/tinymce-react';
import sanitizeHtml from 'sanitize-html';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ loading, setLoading ] = useState(false);

  const editorRef = useRef(null);

  const [ title, setTitle ] = useState("");
  const [ pollOptions, setPollOptions ] = useState("");
  const [ startDate, setStartDate ] = useState("");
  const [ endDate, setEndDate ] = useState("");
  const [  scrollPosition, setScrollPosition ] = useState(0);

  function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(scrollPosition > 0) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  
  async function createPoll() {

    const description = sanitizeHtml(editorRef.current.getContent(), {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img'])
    });

    
    if(!loading) {
      if(title.trim().length > 0) {
        if(pollOptions.trim().length > 0) {
          if(startDate.trim().length > 0) {
            if(endDate.trim().length > 0) {
              if(description.trim().length > 3) {
                setLoading(true);
                await callApi("create_poll.php", {
                  title,
                  pollOptions,
                  description,
                  endDate,
                  startDate,
                }).then(response => {
                  if(response.status === 1) {
                    appContext.tellMessage("Successful");
                    setTitle("");
                    setPollOptions("");
                    setEndDate("");
                    setStartDate("");
                    editorRef.current.setContent("");
    
                  } else {
                    appContext.tellError(response.msg)
                  }
                })
                setLoading(false);
              } else {
                appContext.tellError("Invalid description")
              }
            } else {
              appContext.tellError('Invalid end date');
            }
          } else {
            appContext.tellError("Invalid start date");
          }
        } else {
          appContext.tellError("Invalid poll options");
        }
      } else {
        appContext.tellError("Invalid title");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  return (
    <div className="Section bg-background" >
      
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ marginTop: "20px" }}>
            <h4 className="font-bold">Create Poll</h4>
          </div>
          <div className="col-md-12" style={{ marginTop: "20px" }}>
            <div className="form-group">
              <label>Title</label>
              <input value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" type="text" />
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Start Date</label>
                  <input value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" type="date"/>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>End Date</label>
                  <input value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" type="date"/>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Poll Options</label>
              <textarea className="form-control" value={pollOptions} onChange={(e) => setPollOptions(e.target.value)}></textarea>
              <small style={{ display: "block" }} className="text-muted">Use a comma to separate options</small>
            </div>

            <div className="form-group">
              <label>Description (Body)</label>
              
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=""
                onNodeChange={(e) => {
                  if (e && e.element.nodeName.toLowerCase() == 'img') {
                    editorRef.current.dom.setAttribs(e.element, {
                      display: 'block',
                      width: '100%',
                      height: 'auto',
                    })
                  }
                }}
                init={{
                  height: 500,
                  body_class: "mceEditorBody",
                  promotion: false,
                  branding: false,
                  menubar: true,
                  mobile: {
                    menubar: true
                  },
                  plugins: [
                    'image',
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat' + 'image',
                  //..
                  /* enable title field in the Image dialog*/
                  image_title: true,
                  /* enable automatic uploads of images represented by blob or data URIs*/
                  automatic_uploads: true,
                  /*
                    URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                    images_upload_url: 'postAcceptor.php',
                    here we add custom filepicker only to Image dialog
                  */
                  images_upload_url: BASE_API+'tinymce_post_acceptor.php',
                  image_dimensions: false,
                  file_picker_types: 'image',
                  /* and here's our custom image picker*/
                  file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    /*
                      Note: In modern browsers input[type="file"] is functional without
                      even adding it to the DOM, but that might not be the case in some older
                      or quirky browsers like IE, so you might want to add it to the DOM
                      just in case, and visually hide it. And do not forget do remove it
                      once you do not need it anymore.
                    */

                    input.onchange = function () {
                      var file = this.files[0];

                      var reader = new FileReader();
                      reader.onload = function () {
                        /*
                          Note: Now we need to register the blob in TinyMCEs image blob
                          registry. In the next release this part hopefully won't be
                          necessary, as we are looking to handle it internally.
                        */
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache =  editorRef.current.editorUpload.blobCache;
                        var base64 = reader.result.split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        /* call the callback and populate the Title field with the file name */
                        cb(blobInfo.blobUri(), { title: file.name });
                      };
                      reader.readAsDataURL(file);
                    };

                    input.click();
                  },
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px,  }',
                  //..
                  
                }}
                
              />
              
            </div>

            <div className="form-group text-end">
              <button onClick={createPoll} className="mSupportLoading btn bg-primary">
                {(loading) ? getInlineLoader() : "Create Poll"}
              </button>
            </div>

          </div>

          
        </div>
      </div>
      
    </div>

    
  )
}