import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdSearch,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineSwapRight
} from 'react-icons/ai';
import PollThumbnail from '../ones/PollThumbnail';
import { callApi, formatMoney, getInlineLoader } from "../Helpers";


export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ allDonations, setAllDonations ] = useState([]);
  const [ scrollPosition, setScrollPosition ] = useState(0);
  const [ loading, setLoading ] = useState(false);

  

  async function getAllDonations() {
    await callApi('get_all_donations.php', { }).then(response => {
      if(response.status === 1) {
        setAllDonations(response.data);
      } 
    })
  }

  async function verifyDonation(donationId) {
    setLoading(true);
    await callApi("verify_donation.php", { donationId }).then(response => {
      if(response.status === 1) {
        appContext.tellMessage("Donation was verified");
        getAllDonations();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  async function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getAllDonations();
    setReady(true);
  } 

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  useEffect(() => {
    if(scrollPosition > 0) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])


  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row" >
          <div className="col-md-12" style={{ marginBottom: "20px" }}>
            <h1 className="font-bold">All Donations</h1>
            <h6 className="font-light text-muted">View and Manage donations</h6>
          </div>

          

          {
            (ready) ?
            <>
              {
                (allDonations && allDonations.length > 0) ?
                allDonations.map((item, i) => {
                  return (
                    <div className="col-md-12" key={i} style={{ marginBottom: "20px" }}>
                      <div
                        className="text-start mShadow3"
                        style={{  width: "100%", padding: "10px" }}
                      >
                        <h5 className="font-bold text-muted">{item.name}</h5>
                        <h4>{formatMoney(item.amount)} Tsh</h4>

                        {
                          (item.comment.trim().length > 0) ?
                          <h6 className="font-light" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <span style={{ fontSize: "12px" }} className="font-bold text-muted">COMMENT</span><br/>
                            {item.comment}
                          </h6>
                          :""
                        }

                        {
                          (item.status === 'verified') ?
                          <h6 className="font-light" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <span style={{ fontSize: "12px" }} className="font-bold text-muted">STATUS</span><br/>
                            <span className="text-success font-medium">{item.status}</span>
                          </h6>
                          :
                          <h6 className="font-light" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <span style={{ fontSize: "12px" }} className="font-bold text-muted">STATUS</span><br/>
                            <span className="text-danger font-medium">{item.status}</span>
                          </h6>
                        }

                        <h6 className="text-end font-medium text-muted">{item.date}</h6>
                        {
                          (item.status !== 'verified') ?
                          <div className="text-end">
                            <button
                              className="btn bg-primary"
                              onClick={() => verifyDonation(item.id)}
                            >
                              Verify
                            </button>
                          </div>
                          :""
                        }
                      </div>
                      
                    </div>
                  )
                })
                :
                <h6 className="font-light">No donations were found</h6>
              }
            </>
            :
            <div className="col-md-12 mSupportLoading">
              {getInlineLoader()}
            </div>
          }

          
        </div>
        
      </div>
      
    </div>

    
  )
}