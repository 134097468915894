import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { MdDashboard, MdDarkMode, MdOutlineDarkMode, MdOutlineClose } from 'react-icons/md';
import { TbMenu, TbLogin } from 'react-icons/tb';
import logo from '../assets/logo.png';
import { AiOutlineMenu, AiOutlineArrowLeft, AiOutlineClose } from 'react-icons/ai';

export default function TopBar (props) {

  const appContext = useContext(AppContext);

  const [ title, setTitle ] = useState(props.title);
  const [ isViewer, setIsViewer ] = useState(props.isViewer);

  function toggleSideBar() {
    appContext.setIsSideBarClosed(prev => !prev);
  }

  useEffect(() => {
    setTitle(props.title);
    setIsViewer(props.isViewer)
  }, [ props.isViewer, props.title ])

  return (
    <div className="TopBar">
      <div className="container d-flex" >
        <AiOutlineMenu
          size={30} 
          style={{ flexShrink: "0", cursor: "pointer" }} 
          className="mNoMargin mNoPadding align-self-center mobileOnly"
          onClick={() => appContext.setIsSideBarClosed(!appContext.isSideBarClosed)}
        />
        <img
          src={logo}
          className="align-self-center"
          height="150%"
          width="auto"
          style={{
            filter: "grayscale(100%) brightness(0)",
            cursor: "pointer",
          }}
          onClick={() => {
            appContext.navTo({
              item: "home",
            })
          }}
        />
        
        
        <div 
          className="align-self-center d-flex justify-content-center"
          style={{
            flexGrow: "1",
            height: "auto",
            overflow: "hidden",
          }}
        >
          <button 
            className="btn desktopOnly mMenuItem align-self-center"
            onClick={() => {
              appContext.navTo({
                item: "home",
                subItem: "events"
              })
            }}
          >
            Events
          </button>

          <button 
            className="btn desktopOnly mMenuItem align-self-center"
            onClick={() => {
              appContext.navTo({
                item: "home",
                subItem: "marketplace"
              })
            }}
          >
            MarketPlace
          </button>

          <button 
            className="btn desktopOnly mMenuItem align-self-center"
            onClick={() => {
              appContext.navTo({
                item: "home",
                subItem: "polls"
              })
            }}
          >
            Voting
          </button>

          <button 
            className="btn desktopOnly mMenuItem align-self-center"
            onClick={() => {
              appContext.navTo({
                item: "home",
                subItem: "mymiles"
              })
            }}
          >
            MyMiles
          </button>

          <button 
            className="btn desktopOnly mMenuItem align-self-center"
            onClick={() => {
              appContext.navTo({
                item: "home",
                subItem: "support-us"
              })
            }}
          >
            Support Us
          </button>

          <button 
            className="btn desktopOnly mMenuItem align-self-center"
            onClick={() => {
              appContext.navTo({
                item: "home",
                subItem: "about-us"
              })
            }}
          >
            About Us
          </button>

          <a
            className="btn desktopOnly mMenuItem align-self-center"
            href="http://blog.runtanzania.org"
            target="_blank"
          >
            Blog
          </a>

        </div>

        {
          (appContext.auth && appContext.userData) ?
          <button
            className="btn btn-rounded bg-dark align-self-center font-light btn-sm"
            style={{
              height: "40px",
              paddingLeft: "20px",
              paddingRight: "20px",
              flexShrink: "0",
            }}
            onClick={() => appContext.navTo({ item: "portal" })}
          >
            Dashboard
          </button>
          :
          <button
            className="btn btn-rounded bg-dark align-self-center font-light btn-sm"
            style={{
              height: "40px",
              paddingLeft: "20px",
              paddingRight: "20px",
              flexShrink: "0",
            }}
            onClick={() => appContext.navTo({ item: "portal" })}
          >
            Log In
          </button>
        }
      </div>
    </div>
  );
}