import { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Toaster, toast } from 'react-hot-toast';
import { getMainView, getViewerView, getCookie, setCookie, callApi } from "./Helpers";
import TopBar from "./components/TopBar";
import MainBody from "./components/MainBody";
import SideBar from "./components/SideBar";
import MainLoader from "./components/MainLoader";
import Viewer from './components/Viewer';
import ConfirmDialog from "./components/ConfirmDialog";
import Footer from "./components/Footer";

export const AppContext = createContext(null);

export default function(props) {
  const { _navItem, _navSubItem, _navExtraItem, _navMoreItem } = useParams(); //for routing purposes
  const [ navItem, setNavItem ] = useState((_navItem) ? _navItem : 'home');
  const [ navSubItem, setNavSubItem ] = useState(_navSubItem);
  const [ navExtraItem, setNavExtraItem ] = useState(_navExtraItem);
  const [ navMoreItem, setNavMoreItem ] = useState(_navMoreItem);
  const [ isReady, setIsReady ] = useState(false);
  const [ showViewer, setShowViewer ] = useState(false);
  const [ viewerView, setViewerView ] = useState(null);
  const [ mainView, setMainView ] = useState(null);
  const [ showLoader, setShowLoader ] = useState(false);
  const [ isSideBarClosed, setIsSideBarClosed ] = useState(true);
  const [ auth, setAuth ] = useState(false);
  const [ userData, setUserData ] = useState(null);
  const [ showDialog, setShowDialog ] = useState(false);
  const [ dialogMessage, setDialogMessage ] = useState("");
  const [ dialogOnConfirm, setDialogOnConfirm ] = useState(null);


  function navBack() {
    window.history.back();
  }

  function tellError(error) {
    toast.error(error, {
      style: {
        borderRadius: '0px',
        background: 'var(--surfaceColor)',
        color: 'var(--onSurfaceColor)',
      },
    });
  }

  function tellMessage(message) {
    toast.success(message, {
      style: {
        borderRadius: '0px',
        background: 'var(--surfaceColor)',
        color: 'var(--onSurfaceColor)',
      },
    });
  }

  function refresh() {
    //init()
    window.location.reload();
  }

  function activateDialog(params) {
    let {
      message,
      onConfirm
    } = params;
    setDialogOnConfirm(() => { return onConfirm });
    setDialogMessage(message);
    setShowDialog(true);
  }

  function scrollToTop() {
    window.scrollTo(0,0);
  }

  function getCurrentScrollPosition() {
    const position = window.scrollY;
    return position;
  }

  function restoreScroll(position) {
    window.scrollTo(0, position);
  }
  
  function navTo(nav) {
    
    if(nav) {
      //..
      let url = '';
      if(nav.item) {
        url = `#/${nav.item}/`
      }

      if(nav.subItem) {
        url += `${nav.subItem}/`
      }

      if(nav.extraItem) {
        url += `${nav.extraItem}/`
      }

      if(nav.moreItem) {
        url += `${nav.moreItem}/`
      }

      window.location.href = url;
      //..
    }
  }

  async function init() {
    //initialize here
    setIsReady(false);
    checkAuth().then(async _auth => {
      if(_auth) {
        await getUserData().then(_data => {
          if(_data) {
            setIsReady(true);
          } else {
            setAuth(false);
            setIsReady(true);
          }
        })
      } else {
        setIsReady(true)
      }
    })
  }

  function deleteLocalUsers() {
    return new Promise(async resolve => {
      setCookie('userId', '', -30);
      setCookie('userToken', '', -30);
      resolve(true);
    })
  }

  function saveLocalUser(user) {
    return new Promise (async resolve => {
      //delete local users firt
      await deleteLocalUsers().then(async () => {
        //thenn save this user
        setCookie('userId', user.userId, 90);
        setCookie('userToken', user.userToken, 90);
        resolve(true);
        
      })
    })
  }


  function getLocalUser() {
    return new Promise(async resolve => {
      //use cookies
      let userId = getCookie('userId');
      let userToken = getCookie('userToken');
      resolve({
        userId,
        userToken,
      })

      
    })
  }

  async function checkAuth() {
    
    return new Promise(async resolve => {
      await callApi('check_auth.php', {}).then(response => {
        if(response.status === 1) {
          setAuth(response.auth);
          resolve(response.auth);
        } else {
          //appContext.tellError(response.msg);
          resolve(false);
        }
      })
    })
  }

  async function getUserData() {
    
    return new Promise(async resolve => {
      await callApi('get_current_user.php', {}).then(response => {
        if(response.status === 1) {
          setUserData(response.userData);
          resolve(response.userData);
        } else {
          appContext.tellError(response.msg);
          resolve(false);
        }
      })
    })
  }

  const appContext = {
    navBack,
    navTo,
    checkAuth,
    navItem,
    navSubItem,
    navExtraItem,
    navMoreItem,
    mainView,
    viewerView,
    showViewer,
    isReady,
    showLoader,
    setShowLoader,
    setShowViewer,
    isSideBarClosed,
    setIsSideBarClosed,
    refresh,
    scrollToTop,
    getCurrentScrollPosition,
    tellMessage,
    tellError,
    auth,
    userData,
    getLocalUser,
    saveLocalUser,
    deleteLocalUsers,
    activateDialog,
    restoreScroll,
  }

  useEffect(() => {
    //console.log('Mainview changed');
    //console.log(mainView);
  }, [ mainView ])

  useEffect(() => {
    if(!navItem) {
      setNavItem('home')
    }

    //check for viewerView
    if(navItem === 'view' ) {
      //activate viewer
      setShowViewer(true);
      setViewerView(getViewerView(appContext));
      
    } else {
      //just set normal views
      setShowViewer(false);
      setViewerView(null);
      setMainView((prevView) => {
        return getMainView(appContext);
      })
    }
  }, [ navItem, navSubItem, navExtraItem, navMoreItem ]);


  useEffect(() => {
    setNavItem((_navItem) ? _navItem : 'home');
    setNavSubItem(_navSubItem);
    setNavExtraItem(_navExtraItem);
    setNavMoreItem(_navMoreItem);
  }, [ _navItem, _navSubItem, _navExtraItem, _navMoreItem ])


  useEffect(() => {
    init();
  }, [ ])

  
  if(isReady) {
    return (
      <AppContext.Provider value={appContext}>
        <Toaster
          position="bottom-right"
          reverseOrder={true}
          containerStyle={{
            zIndex: "calc(1 + var(--maxIndex))"
          }}
        />
        <MainLoader show={showLoader}/>
        <Viewer/>
        <ConfirmDialog show={showDialog} message={dialogMessage} onConfirm={dialogOnConfirm}/>
        <TopBar/>
        <SideBar/>
        <MainBody/>
        <Footer/>
      </AppContext.Provider>
    )
  } else {
    return (
      <AppContext.Provider value={appContext}>
        <Toaster
          position="bottom-right"
          reverseOrder={true}
          containerStyle={{
            zIndex: "calc(1 + var(--maxIndex))"
          }}
        />
        <MainLoader show={true}/>
        <ConfirmDialog show={showDialog} message={dialogMessage} onConfirm={dialogOnConfirm}/>
      </AppContext.Provider>
    )
  }
}