import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineSwapRight
} from 'react-icons/ai';
import PollThumbnail from '../ones/PollThumbnail';
import { callApi, getInlineLoader } from "../Helpers";


export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ openPolls, setOpenPolls ] = useState([]);
  const [  scrollPosition, setScrollPosition ] = useState(0);


  async function getOpenPolls() {
    await callApi('get_open_polls.php', { }).then(response => {
      if(response.status === 1) {
        setOpenPolls(response.data);
      } 
    })
  }

  async function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getOpenPolls();
    setReady(true);
  } 

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(scrollPosition > 0 && !props.isHome) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])


  return (
    <div className="Section bg-dark" >
      
      <div className="container">

        <div className="row" >
          <div className="col-md-12">
            <h1 className="font-bold">Open Polls</h1>
            <h3 className="font-light text-muted">Vote for your favorites</h3>
          </div>

          {
            (ready) ?
            <div className="col-md-12">
              {
                (openPolls && openPolls.length > 0) ?
                <div className="d-flex mHorizontalScroll">
                  {
                    openPolls.map((item, i) => {
                      return (
                        <PollThumbnail data={item} key={i}/>
                      )
                    })
                  }
                </div>
                :
                <h6 className="font-light">No open polls were found</h6>
              }
            </div>
            :
            <div className="col-md-12 mSupportLoading">
              {getInlineLoader()}
            </div>
          }

          <div className="col-md-12 text-end">
            <button
              className="btn btn-sm text-light font-bold"
              onClick={() => {
                appContext.navTo({
                  item: 'home',
                  subItem: 'polls',
                })
              }}
            >
              View All Open Polls <AiOutlineSwapRight size={20}/>
            </button>
          </div>
        </div>
        
      </div>
      
    </div>

    
  )
}