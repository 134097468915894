import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdOutlineShare
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';
import { IoTicketOutline } from 'react-icons/io5';
import { BASE_API, callApi, copyText, formatDate, formatMoney, getInlineLoader } from "../Helpers";

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ data, setData ] = useState(null);
  const [ id, setId ] = useState(props.id);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [  scrollPosition, setScrollPosition ] = useState(0);


  async function getItemData() {
    await callApi("get_market_item_data.php", { itemId: id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function copyLink() {
    appContext.tellMessage("Item link was copied")
    await copyText("https://runtanzania.org/#/marketplace/"+data.uid);
  }

  async function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getItemData();
    setReady(true);
  }

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(scrollPosition > 0) {
      //appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  if(ready) {
    if(data) {
      return (
        <div className="Section bg-background">
          <div className="container">
            <div className="row">
              
              <div className="col-md-12">
                <img src={BASE_API+data.coverPhoto} width="100%" height="auto"/>
              </div>

              <div className="col-md-12">
                <h1
                  className="font-bold"
                  style={{
                    fontSize: "50px"
                  }}
                >
                  {data.title}
                </h1>

                <div className="text-end" style={{ marginTop: "30px" }}>
                  <button onClick={copyLink} className="btn btn-sm text-primary mShadow3 font-bold" style={{ margin: "5px" }}><MdOutlineShare size={20}/> Copy Link</button>
                </div>
                <hr/>
              </div>

              <div className="col-md-12">
                <div
                  dangerouslySetInnerHTML={{__html: data.description}}
                  style={{ 
                    maxWidth:  "992px",
                    margin: "0 auto"
                  }}
                >
                </div>
                <hr/>
              </div>

              
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="Section bg-background">
          <div className="mSupportLoading" style={{ width: "100%" }}>
            {getInlineLoader()}
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className="Section bg-background">
        <div className="mSupportLoading" style={{ width: "100%" }}>
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}