import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import { callApi, getInlineLoader } from "../Helpers";
import SwipeTabs from "../components/SwipeTabs";

export default function Login() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirm, setConfirm ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ showLogin, setShowLogin ] = useState(true);
  const [ showRegister, setShowRegister ] = useState(false);
  const [ showReset, setShowReset ] = useState(false);
  const [  scrollPosition, setScrollPosition ] = useState(0);

  function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(scrollPosition > 0) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  async function reset() {
    if(!loading) {
      if(email.trim().length > 0) {
        setLoading(true);
        await callApi("reset_password.php", { email }).then(response => {
          if(response.status === 1) {
            appContext.tellMessage(response.msg);
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError("Invalid email address");
      }
    }
  }

  async function register() {
    if(!loading) {
      if(email.trim().length > 0) {
        if(password.trim().length >= 6) {
          if(password === confirm) {
            //..
            setLoading(true);
            await callApi("register.php", { email, password }).then(response => {
              if(response.status === 1) {
                appContext.saveLocalUser({ userId: response.userId, userToken: response.userToken });
                appContext.refresh();
                appContext.tellMessage("Registration successful");
              } else {
                appContext.tellError(response.msg);
              }
            })
            setLoading(false);
            //..
          } else {
            appContext.tellError("Please confirm your assword correctly");
          }
        } else {
          appContext.tellError("Invalid password, password must have atleast 6 characters")
        }
      } else {
        appContext.tellError('Invalid email address');
      }
    } else {
      appContext.tellError("Please wait....");
    }
  }

  async function login() {
    if(!loading) {
      if(email.trim().length > 0) {
        if(password.trim().length > 0) {

          setLoading(true);
          await callApi("login.php", { email, password }).then(response => {
            if(response.status === 1) {
              appContext.saveLocalUser({ userId: response.userId, userToken: response.userToken });
              appContext.refresh();
              appContext.tellMessage("Welcome back");
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);

        } else {
          appContext.tellError('Invalid password');
        }
      } else {
        //invalid email address
        appContext.tellError('Invalid email')
      }
    }
  }

  function showLoginForm() {
    setShowLogin(true);
    setShowRegister(false);
    setShowReset(false);
    setPassword('');
    setEmail('');
    setConfirm('');
  }

  function showRegisterForm() {
    setShowLogin(false);
    setShowRegister(true);
    setShowReset(false);
    setPassword('');
    setEmail('');
    setConfirm('');
  }

  function showResetForm() {
    setShowLogin(false);
    setShowRegister(false);
    setShowReset(true);
    setPassword('');
    setEmail('');
    setConfirm('');
  }
  
  function init () {
    //intialize this view here
    setReady(true)
  }

  useEffect(() => {
    init()
  }, [ ])

  //return render
  if(ready) {
    if(showLogin) {
      //...
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1  className="font-light" style={{ marginTop: "20px" }}>Login</h1>
              <small className="form-text">Login to continue</small>
              <div className="form-group">
                <label>Email Address</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" type="text"/>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" type="password"/>
              </div>
              <div className="form-group text-end">
                <button onClick={login} className="mSupportLoading btn bg-primary">
                  {(loading) ? getInlineLoader() : "Login"}
                </button>
              </div>
              <div className="form-group text-start">
                <h6>Do not have an account? <span onClick={showRegisterForm} style={{ cursor: "pointer" }} className="font-bold text-primaru">Register here</span></h6> 
                <h6>Forgot your password? <span onClick={showResetForm} style={{ cursor: "pointer" }} className="font-bold text-primaru">Reset it here</span></h6> 
              </div>
            </div>
          </div>
        </div>
      )
      ///..
    } else if(showRegister) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1  className="font-light" style={{ marginTop: "20px" }}>Register</h1>
              <small className="form-text">Create an account on RUNTanzania Portal</small>
              <div className="form-group">
                <label>Email Address</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" type="text"/>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" type="password"/>
              </div>
              <div className="form-group">
                <label>Confirm password</label>
                <input value={confirm} onChange={(e) => setConfirm(e.target.value)} className="form-control" type="password"/>
              </div>
              <div className="form-group text-end">
                <button onClick={register} className="mSupportLoading btn bg-primary">
                  {(loading) ? getInlineLoader() : "Register"}
                </button>
              </div>
              <div className="form-group text-start">
                <h6>Already have an account? <span onClick={showLoginForm} style={{ cursor: "pointer" }} className="font-bold text-primaru">Login here</span></h6> 
                <h6>Forgot your password? <span onClick={showResetForm} style={{ cursor: "pointer" }} className="font-bold text-primaru">Reset it here</span></h6> 
              </div>
            </div>
          </div>
        </div>
      )
    } else if(showReset) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1  className="font-light" style={{ marginTop: "20px" }}>Reset Password</h1>
              <small className="form-text">Reset your RUNTanzania.org password here</small>
              <div className="form-group">
                <label>Email Address</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" type="text"/>
              </div>
              
              <div className="form-group text-end">
                <button onClick={reset} className="mSupportLoading btn bg-primary">
                  {(loading) ? getInlineLoader() : "Reset Password"}
                </button>
              </div>
              <div className="form-group text-start">
                <h6>Already have an account? <span onClick={showLoginForm} style={{ cursor: "pointer" }} className="font-bold text-primaru">Login here</span></h6> 
                <h6>Want to create an account? <span onClick={showRegisterForm} style={{ cursor: "pointer" }} className="font-bold text-primaru">Register here</span></h6> 
              </div>
            </div>
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }
  


  
}
