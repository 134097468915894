import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdOutlineShare,
  MdOutlineHowToVote
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';
import { BsAward } from "react-icons/bs";
import { IoTicketOutline } from 'react-icons/io5';
import { BASE_API, callApi, copyText, formatDate, formatMoney, getInlineLoader } from "../Helpers";

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ data, setData ] = useState(null);
  const [ id, setId ] = useState(props.id);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [  scrollPosition, setScrollPosition ] = useState(0);


  async function getPollData() {
    await callApi("get_poll_data.php", { pollId: id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function castVote(selectedOption) {
    if(appContext.auth && appContext.userData) {
      if(appContext.userData.status === 'verified') {
        //..
        setLoading(true)
        await callApi("cast_vote.php", { selectedOption, pollId: id }).then((response) => {
          if(response.status === 1) {
            appContext.tellMessage("Your vote was received, thanks");
            init();
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
        //..
      } else {
        appContext.tellError("Please verify your account first");
        appContext.navTo({
          item: 'portal',
        })
      }
    } else {
      appContext.tellError("Please login first");
      appContext.navTo({
        item: 'portal',
      })
    }
    
  }

  async function copyLink() {
    appContext.tellMessage("Poll link was copied")
    await copyText("https://runtanzania.org/#/poll/"+data.uid);
  }

  async function init() {
    //get current position
    setReady(false);
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getPollData();
    setReady(true);
  }

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(scrollPosition > 0) {
      //appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  if(ready) {
    if(data) {
      return (
        <div className="Section bg-background">
          <div className="container">
            <div className="row">
            

              <div className="col-md-12">
                <BsAward size={80}/>
                <h1
                  className="font-bold"
                  style={{
                    fontSize: "50px"
                  }}
                >
                  {data.title}
                </h1>

                <div className="text-end" style={{ marginTop: "30px" }}>
                  <h6><span className="font-bold">From </span>{(new Date(data.startDate * 1000)).toString().substring(0,15)} <span className="font-bold">to</span> {(new Date(data.endDate * 1000)).toString().substring(0,15)}</h6>
                  <button onClick={copyLink} className="btn btn-sm text-primary mShadow3 font-bold" style={{ margin: "5px" }}><MdOutlineShare size={20}/> Copy Link</button>
                  <button onClick={() => document.getElementById('_options').scrollIntoView({ behavior: "smooth" })} className="btn btn-sm text-primary mShadow3 font-bold" style={{ margin: "5px" }}><MdOutlineHowToVote size={20}/> Vote</button>
                </div>
                <hr/>
              </div>

              <div className="col-md-12">
                <div
                  dangerouslySetInnerHTML={{__html: data.description}}
                  style={{ 
                    maxWidth:  "992px",
                    margin: "0 auto"
                  }}
                >
                </div>
                <hr/>
              </div>

              <div className="col-md-12" id="_options">
                <h4 style={{ marginBottom: "30px", marginTop: "30px" }} className="text-center">
                  <MdOutlineHowToVote size={70} className="text-primary"/><br/>
                  

                  {
                    (
                      appContext.auth &&
                      appContext.userData &&
                      JSON.parse(data.votes).length > 0
                    ) ?
                    <>
                    {
                      JSON.parse(data.votes).map((item, i) => {
                        if(item.userId === appContext.userData.id) {
                          return "You voted for "+item.selectedOption;
                        }
                      })
                    }
                    </>
                    :"Vote For Your Choice!"
                  }
                </h4>

                <div className="row">
                {
                  JSON.parse(data.options).map((item, i) => {
                    return (
                      <div className="col-md-3" key={i}>
                        <div className="mShadow3" style={{ width: "100%", padding: "10px", borderRadius: "10px", marginBottom: "20px" }}>
                          <h5 className="font-bold">{item}</h5>

                          <button
                            className="btn bg-primary btn-block"
                            style={{ 
                              fontSize: "16px",
                              marginTop: "40px",
                            }}
                            onClick={() => castVote(item)}
                          >
                            Vote for {item}
                          </button>
                        </div>
                      </div>
                    )
                  })
                }
                </div>
              </div>

              
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="Section bg-background">
          <div className="mSupportLoading" style={{ width: "100%" }}>
            {getInlineLoader()}
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className="Section bg-background">
        <div className="mSupportLoading" style={{ width: "100%" }}>
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}