import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { AiOutlineUser } from 'react-icons/ai'
import logo from '../assets/logo.png';
import { GrPlan } from 'react-icons/gr'
import { HiOutlineDocumentReport, HiOutlineUserGroup } from 'react-icons/hi'
import { TbLayoutDashboard } from 'react-icons/tb';
import { AiOutlineSchedule, AiOutlineSetting, AiOutlineClose } from 'react-icons/ai';

export default function SideBar (props) {

  const appContext = useContext(AppContext);

  const goTo = (id, subId) => {
    appContext.setIsSideBarClosed(true);
    appContext.navTo({
      item: id,
      subItem: subId,
    })
  }

  return (
    <>
      <div className="SideBar" data-closed={appContext.isSideBarClosed}>
        <div className="mContainer">
          
          <div onClick={() => goTo('home', 'events')} className="mItem d-flex mBorder10" >
            <p className="mTitle">Events</p>
          </div>

          <div onClick={() => goTo('home', 'marketplace')} className="mItem d-flex mBorder10">
            <p className="mTitle">Marketplace</p>
          </div>

          <div onClick={() => goTo('home', 'polls')} className="mItem d-flex mBorder10" >
            <p className="mTitle">Voting</p>
          </div>

          <div onClick={() => goTo('home', 'mymiles')} className="mItem d-flex mBorder10">
            <p className="mTitle">MyMiles</p>
          </div>

          <div onClick={() => goTo('home', 'support-us')} className="mItem d-flex mBorder10">
            <p className="mTitle">Support Us</p>
          </div>

          <div onClick={() => goTo('home', 'about-us')} className="mItem d-flex mBorder10">
            <p className="mTitle">About Us</p>
          </div>

          <div onClick={() => window.location.href = 'https://blog.runtanzania.org'} className="mItem d-flex mBorder10">
            <p className="mTitle">Blog</p>
          </div>
          
        </div>
      </div>
      <div onClick={() => appContext.setIsSideBarClosed(true)} className="Paper" style={{ display: (appContext.isSideBarClosed) ? "none" : "block" }}></div>
    </>
    
  );
}