import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, getInlineLoader } from "../Helpers";
import Portal from "./Portal";
import DashboardHome from '../views/DashboardHome';
import PostEvent from '../views/PostEvent';
import PostMarketplaceItem from '../views/PostMarketplaceItem';
import CreatePoll from '../views/CreatePoll';
import AllDonations from '../views/AllDonations';
import AllSponsorships from '../views/AllSponsorships';

export default function Dashboard(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);

  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])

  //return render
  if(ready) {
    if(appContext.auth && appContext.userData) {
      if(appContext.userData.role === 'admin') {
        //render admin dashboard
        
        if(!appContext.navSubItem && !appContext.navExtraItem && !appContext.navMoreItem ) {
          return <DashboardHome/>;
        } else if(appContext.navSubItem === 'post_event' && !appContext.navExtraItem && !appContext.navMoreItem) {
          return <PostEvent/>;
        } else if(appContext.navSubItem === 'post_marketplace_item' && !appContext.navExtraItem && !appContext.navMoreItem) {
          return <PostMarketplaceItem/>;
        } else if(appContext.navSubItem === 'create_poll' && !appContext.navExtraItem && !appContext.navMoreItem) {
          return <CreatePoll/>;
        } else if(appContext.navSubItem === 'all_donations' && !appContext.navExtraItem && !appContext.navMoreItem) {
          return <AllDonations/>;
        } else if(appContext.navSubItem === 'all_sponsorships' && !appContext.navExtraItem && !appContext.navMoreItem) {
          return <AllSponsorships/>;
        } 
      } else {
        //render portal here
        return <Portal/>
      }
      
    } else {
      return <Login/>
    }
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
