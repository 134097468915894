import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, getInlineLoader } from "../Helpers";
import Dashboard from "./Dashboard";
import PortalHome from "../views/PortalHome";
import MyVotes from '../views/MyVotes';
import MyDonations from '../views/MyDonations';
import MySponsorships from '../views/MySponsorships';
import MyTickets from '../views/MyTickets';

export default function Portal() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);

  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])

  //return render
  if(ready) {
    if(appContext.auth && appContext.userData) {
      if(appContext.userData.role === 'admin') {
        //render admin dashboard
        return <Dashboard/>
      } else {
        //render portal here
        if(!appContext.navSubItem && !appContext.navExtraItem && !appContext.navMoreItem ) {
          return <PortalHome/>;
        } else if(appContext.navSubItem === 'my_votes' && !appContext.navExtraItem && !appContext.navMoreItem ) {
          return <MyVotes/>;
        } else if(appContext.navSubItem === 'my_donations' && !appContext.navExtraItem && !appContext.navMoreItem ) {
          return <MyDonations/>;
        } else if(appContext.navSubItem === 'my_sponsorships' && !appContext.navExtraItem && !appContext.navMoreItem ) {
          return <MySponsorships/>;
        } else if(appContext.navSubItem === 'my_tickets' && !appContext.navExtraItem && !appContext.navMoreItem ) {
          return <MyTickets/>;
        } 
      }
      
    } else {
      return <Login/>
    }
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
