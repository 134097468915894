import { useContext } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineFitnessCenter,
  MdOutlineNewspaper,
  MdConnectWithoutContact,
  MdOutlineEventNote
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';

export default function View(props) {
  const appContext = useContext(AppContext);

  return (
    <div className="Banner">
      
      <div className="container">
        <div className="row">
          <div className="col-md-8">

            <h1
              style={{
                fontSize: "90px",
                marginTop: "calc(0.5 * var(--topBarHeight))"
              }}
              className="font-bold"
            >
              RUN
            </h1>
            <h1
              style={{
                fontSize: "60px",
                marginTop: "-25px"
              }}
              className="font-light"
            >
              Tanzania<span className="font-bold">&reg;</span>
            </h1>

            <h1
              style={{
                fontSize: "40px",
                marginTop: "calc(0.5 * var(--topBarHeight))"
              }}
              className="font-bold"
            >
              Enriched Lifestyles
            </h1>

            <h3 className="font-light">
              We Organize and Promote Sports Activities, Tourism, Healthy Lifestyles and Expos through Various Means and Methods
            </h3>
          </div>

          <div className="col-md-4">
            

          </div>
        </div>

        
      </div>
      
    </div>

    
  )
}