import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import Banner from '../views/Banner';
import About from '../views/About';
import HomeActions from '../views/HomeActions';
import LatestEvents from '../views/LatestEvents';
import AllEvents from '../views/AllEvents';
import MyMilesIntro from '../views/MyMilesIntro';
import OpenPolls from '../views/OpenPolls';
import AllOpenPolls from '../views/AllOpenPolls';
import SupportUsIntro from '../views/SupportUsIntro';
import MarketplaceIntro from '../views/MarketplaceIntro';
import NyikatimesIntro from '../views/NyikatimesIntro';
import Marketplace from '../views/Marketplace';
import SponsorPage from '../views/SponsorPage';
import DonatePage from '../views/DonatePage';
import { callApi, getInlineLoader } from "../Helpers";

export default function Home() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);

  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])

  //return render
  if(ready) {
    //real render here
    if(!appContext.navSubItem && !appContext.navExtraItem && !appContext.navMoreItem ) {
      return (
        <>
          <Banner isHome={true}/>
          <HomeActions isHome={true}/>
          <NyikatimesIntro isHome={true}/>
          <LatestEvents isHome={true}/>
          <MyMilesIntro isHome={true}/>
          <OpenPolls isHome={true}/>
          <SupportUsIntro isHome={true}/>
          <MarketplaceIntro isHome={true}/>
          <About isHome={true}/>
        </>
      )
    } else if (appContext.navSubItem === 'events' && !appContext.navExtraItem && !appContext.navMoreItem) {
      return <AllEvents/>
    } else if (appContext.navSubItem === 'polls' && !appContext.navExtraItem && !appContext.navMoreItem) {
      return <AllOpenPolls/>
    } else if (appContext.navSubItem === 'marketplace' && !appContext.navExtraItem && !appContext.navMoreItem) {
      return <Marketplace/>
    } else if (appContext.navSubItem === 'support-us' && !appContext.navExtraItem && !appContext.navMoreItem) {
      return <SupportUsIntro/>
    } else if (appContext.navSubItem === 'about-us' && !appContext.navExtraItem && !appContext.navMoreItem) {
      return <About/>
    } else if (appContext.navSubItem === 'mymiles' && !appContext.navExtraItem && !appContext.navMoreItem) {
      return <MyMilesIntro/>
    } else if (appContext.navSubItem === 'sponsor_page' && !appContext.navExtraItem && !appContext.navMoreItem) {
      return <SponsorPage/>
    } else if (appContext.navSubItem === 'donate_page' && !appContext.navExtraItem && !appContext.navMoreItem) {
      return <DonatePage/>
    }
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
