import { useContext } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdDirectionsRun,
  MdOutlineHowToVote,
  MdAppsOutage
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';

export default function View(props) {
  const appContext = useContext(AppContext);

  return (
    <div className="Section bg-primary" >
      
      <div className="container">

        <div className="row" style={{ marginTop: "calc(1 * var(--topBarHeight))" }}>

          <div className="col-6 col-md-3" style={{ marginBottom: "25px" }}>
            <button
              className="btn bg-primary text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "14px",
              }}
              onClick={() => {
                appContext.navTo({
                  item: "home",
                  subItem: "events"
                })
              }}
            >
              <span style={{ fontSize: "40px" }}><MdOutlineEventNote size={40}/> 9+</span><br/>
              <span style={{ fontSize: "20px" }}>Events</span><br/><br/>
              <span className="font-light" style={{ fontSize: "16px" }}>Marathons, bonanzas, expos. Click to view recent and past events.</span>
            </button>
          </div>

          <div className="col-6 col-md-3" style={{ marginBottom: "25px" }}>
            <button
              className="btn bg-primary text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "14px",
              }}
              onClick={() => {
                appContext.navTo({
                  item: "home",
                  subItem: "marketplace"
                })
              }}
            >
              <span style={{ fontSize: "40px" }}><MdAppsOutage size={40}/> 15+</span><br/>
              <span style={{ fontSize: "20px" }}>Marketplace</span><br/><br/>
              <span className="font-light" style={{ fontSize: "16px" }}>Discover and advertise goods, products and services in our market</span>
            </button>
          </div>

          <div className="col-6 col-md-3" style={{ marginBottom: "25px" }}>
            <button
              className="btn bg-primary text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "14px",
              }}
              onClick={() => {
                appContext.navTo({
                  item: "home",
                  subItem: "polls"
                })
              }}
            >
              <span style={{ fontSize: "40px" }}><MdOutlineHowToVote size={40}/> 20+</span><br/>
              <span style={{ fontSize: "20px" }}>Voting & Polls</span><br/><br/>
              <span className="font-light" style={{ fontSize: "16px" }}>See open polls and vote for your favorites</span>
            </button>
          </div>

          <div className="col-6 col-md-3" style={{ marginBottom: "25px" }}>
            <button
              className="btn bg-primary text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "14px",
              }}
              onClick={() => {
                appContext.navTo({
                  item: "home",
                  subItem: "mymiles"
                })
              }}
            >
              <span style={{ fontSize: "40px" }}><MdDirectionsRun size={40}/> 1</span><br/>
              <span style={{ fontSize: "20px" }}>Fitness App</span><br/><br/>
              <span className="font-light" style={{ fontSize: "16px" }}>Download 'MyMiles' App to track your fitness activities</span>
            </button>
          </div>

        </div>
        
      </div>
      
    </div>

    
  )
}