import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import sponsor1 from '../assets/images/sponsor1.jpg';
import proposal from '../assets/proposal.pdf';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdOutlineFileDownload,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineApple,
  AiOutlineAndroid
} from 'react-icons/ai';
import { IoLogoGooglePlaystore } from 'react-icons/io5';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [  scrollPosition, setScrollPosition ] = useState(0);

  function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(scrollPosition > 0 && !props.isHome) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row">
          <div className="col-md-12">
            <h1 className="font-bold">Support Us</h1>
          </div>

          <div className="col-md-8">
            <img src={sponsor1} width="100%" height="auto"/>
          </div>

          <div className="col-md-4">
            <h5 style={{ marginTop: "20px", marginBottom: "20px" }} className="font-light text-muted">Our Donors and Sponsors get the privilege to showcase their products and services on our platforms and during various events</h5>

            <div className="row">
              <div className="col-6">
                <button
                  className="btn bg-primary btn-block"
                  style={{
                    fontSize: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  onClick={() => {
                    appContext.navTo({
                      item: 'home',
                      subItem: 'donate_page',
                    })
                  }}
                >
                  Donate
                </button>

              </div>

              <div className="col-6">
                <button
                  className="btn bg-primary btn-block"
                  style={{
                    fontSize: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  onClick={() => {
                    appContext.navTo({
                      item: 'home',
                      subItem: 'sponsor_page',
                    })
                  }}
                >
                  Sponsor
                </button>
                
              </div>

              <div className="col-12" style={{ marginTop: "20px" }}>
                <a
                  className="btn bg-dark btn-block"
                  style={{
                    fontSize: "16px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  href={proposal}
                  target="_blank"
                >
                  <MdOutlineFileDownload size={40}/><br/>
                  Download Sponsorship Proposal
                </a>

              </div>
            </div>
          </div>
        </div>

        
      </div>
      
    </div>

    
  )
}