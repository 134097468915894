import axios from "axios";
import { createContext, useContext } from "react";
import Portal from "./main-views/Portal";
import Home from "./main-views/Home";
import Dashboard from "./main-views/Dashboard";
import ViewEvent from './views/ViewEvent';
import ViewPoll from './views/ViewPoll';
import ViewMarketItem from './views/ViewMarketItem';
import ViewTicket from './views/ViewTicket';
//export const BASE_API = "/github_projects/runtanzania/api3/"; 
export const BASE_API = "/api/" ; 

export function getMainView(app) {
  if(app.navItem === 'home') {
    return <Home/>;
  } else if(app.navItem === 'portal') {
    return <Portal/>;
  } else if(app.navItem === 'dashboard') {
    return <Dashboard/>;
  } else if(app.navItem === 'nyikatimes') {
    return "";
  } else if(app.navItem === 'mymiles') {
    return "";
  } else if(app.navItem === 'event' && app.navSubItem) {
    return <ViewEvent id={app.navSubItem} />
  } else if(app.navItem === 'poll' && app.navSubItem) {
    return <ViewPoll id={app.navSubItem} />
  } else if(app.navItem === 'marketplace' && app.navSubItem) {
    return <ViewMarketItem id={app.navSubItem} />
  } else if(app.navItem === 'ticket' && app.navSubItem) {
    return <ViewTicket id={app.navSubItem} />
  }
}

export function getViewerView(app) {
  
  if(
    app.navSubItem === "event" && 
    app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <ViewEvent id={app.navExtraItem} />
  } else if(
    app.navSubItem === "poll" && 
    app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <ViewPoll id={app.navExtraItem} />
  } else if(
    app.navSubItem === "marketplace" && 
    app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <ViewMarketItem id={app.navExtraItem} />
  } else if(
    app.navSubItem === "ticket" && 
    app.navExtraItem && 
    !app.navMoreItem 
  ) {
    return <ViewTicket id={app.navExtraItem} />
  } 
  
}


//..
export async function copyText(text) {
  //navigator.clipboard.writeText(text);
  try {
    window.calipaApp.copyText(text); //hahaa.
  } catch (e) {
    //probably running on web
    await forceCopy(text).then(response => {
      //alert(response)
    }).catch((e) => {
      //alert(e.message)
    });
  }
}

export function forceCopy(text) {
  return new Promise(async (resolve, reject) => {
    if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
      // const type = "text/plain";
      // const blob = new Blob([text], { type });
      // const data = [new ClipboardItem({ [type]: blob })];
      // navigator.permissions.query({name: "clipboard-write"}).then((permission) => {
      //     if (permission.state === "granted" || permission.state === "prompt") {
      //         navigator.clipboard.write(data).then(resolve, reject).catch(reject);
      //     }
      //     else {
      //         reject(new Error("Permission not granted!"));
      //     }
      // });
      await navigator.clipboard.writeText(text).then(() => { });
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";
      textarea.style.width = '2em';
      textarea.style.height = '2em';
      textarea.style.padding = 0;
      textarea.style.border = 'none';
      textarea.style.outline = 'none';
      textarea.style.boxShadow = 'none';
      textarea.style.background = 'transparent';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
          document.execCommand("copy");
          document.body.removeChild(textarea);
          resolve();
      }
      catch (e) {
          document.body.removeChild(textarea);
          reject(e);
      }
    }
    else {
      reject(new Error("None of copying methods are supported by this browser!"));
    }
  });

}
//..


export function formatDate(timestamp, fromPhp = true) {
  timestamp = (fromPhp) ? Number(timestamp) * 1000 : timestamp;
  let date = new Date(timestamp);
  return (date.toLocaleString());
}


export function getInlineLoader() {
  return (
    <div className="Loader">
    </div>
  )
}

export function formatMoney(x) {
  //round x to 2 decimal places before og function
  x = Number(x);
  x = x.toFixed(0);

  //then continue
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function formatMoneyShorten(num, digits = 2) {
  num = Number.parseFloat(num);
  if(num <= 1 && num >= -1 ) {
    return num;
  }

  const lookup = [
   { value: 1, symbol: "" },
   { value: 1e3, symbol: "K" },
   { value: 1e6, symbol: "M" },
   { value: 1e9, symbol: "B" },
   { value: 1e12, symbol: "T" },
   { value: 1e15, symbol: "P" },
   { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}


export function callApi (url, params) {
  return new Promise(async resolve => {
    await getLocalUser().then(user => {
      //..
      //call main api
      let formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }

      for (let key in user) {
        formData.append(key, user[key]);
      }

      axios.post(BASE_API+url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        
        if(process.env.REACT_APP_DEV_MODE) {
          //console.log(url);
          //console.log(params);
          console.log(response.data);
        }

        resolve(response.data);
      }).catch(error => {
        resolve({
          status: 0,
          msg: "Network Connection Error",
        })
      })
      //..
    })
    
  })
}

const getLocalUser = () => {
  return new Promise(async resolve => {
    //use cookies
    let userId = getCookie('userId');
    let userToken = getCookie('userToken');
    resolve({
      userId,
      userToken,
    })

    
  })
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}




