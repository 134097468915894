import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';
import { BsAward } from 'react-icons/bs';
import { BASE_API, formatDate } from "../Helpers";

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ data, setData ] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])

  function view() {
    appContext.navTo({
      item: 'view',
      subItem: 'poll',
      extraItem: data.uid,
    })
  }

  if(props.supportCol) {
    return (
      <div className="col-md-4">
        
        <div className="EventThumbnail" style={{ height: "200px" }} data-support-col={props.supportCol}>
          <div className="mDate text-center">
            <span>Until {formatDate(data.endDate).substring(0,10)}</span>
          </div>
          <div className="mCover" onClick={view} style={{ overflow: "hidden", height: "auto", borderRadius: "0px" }}>
            <div className="text-start" style={{ marginTop: "calc(var(--topBarHeight) * 0.5)" }}>
              <BsAward size={50} className="" style={{ }}/>
              <h4 className="font-medium mNoMargin mNoPadding" style={{ wordWrap: "break-word" }}>
                {data.title}
              </h4>
            </div>
          </div>
          
        </div>
        
      </div>
    )
  } else {
    return (
      <div className="EventThumbnail" style={{ height: "200px" }} data-support-col={props.supportCol}>
        <div className="mDate text-center">
          <span>Until {formatDate(data.endDate).substring(0,10)}</span>
        </div>
        <div className="mCover" onClick={view} style={{ overflow: "hidden", height: "auto", borderRadius: "0px" }}>
          <div className="text-start" style={{ marginTop: "calc(var(--topBarHeight) * 0.5)" }}>
            <BsAward size={50} className="" style={{ }}/>
            <h4 className="font-medium mNoMargin mNoPadding" style={{ wordWrap: "break-word" }}>
              {data.title}
            </h4>
          </div>
        </div>
        
      </div>
    )
  }
}