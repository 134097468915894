import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineSwapRight
} from 'react-icons/ai';
import PollThumbnail from '../ones/PollThumbnail';
import { callApi, getInlineLoader } from "../Helpers";


export default function View(props) {
  const appContext = useContext(AppContext);

  

  return (
    <div className="Section bg-dark" >
      
      <div className="container">

        <div className="row" >
          <div className="col-md-12">
            <h1 className="font-bold">Marketplace</h1>
            <h3 className="font-light text-muted">Discover amazing offers, products and services from our partners, sponsors and donors</h3>
          </div>

          

          <div className="col-md-12 text-end">
            <button
              className="btn btn-sm text-light font-bold"
              onClick={() => {
                appContext.navTo({
                  item: 'home',
                  subItem: 'marketplace',
                })
              }}
            >
              Visit Marketplace <AiOutlineSwapRight size={20}/>
            </button>
          </div>
        </div>
        
      </div>
      
    </div>

    
  )
}