import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { MdDashboard, MdDarkMode, MdOutlineDarkMode } from 'react-icons/md';
import { TbLogin } from 'react-icons/tb';
import { BsInstagram, BsFacebook, BsYoutube } from 'react-icons/bs';
import { AiOutlineFacebook } from 'react-icons/ai';
import PollThumbnail from '../ones/PollThumbnail';
import { callApi, getInlineLoader } from "../Helpers";


export default function Footer(props) {
  const appContext = useContext(AppContext);

  

  return (
    <div className="Section bg-dark" >
      
      <div className="container">

        
        <div className="row">

          <div className="col-md-4" style={{ marginBottom:"20px" }}>
            <h4 className="font-bold">Useful Links</h4>
            <a href="http://blog.runtanzania.org"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>RUNTanzania Blog</h6></a>
            <a href="https://www.youtube.com/@runtanzania"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>NYIKA Times</h6></a>
            <a href="http://calipa.co.tz"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>CALIPA</h6></a>
            <a href="#"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>MyMiles - Google PlayStore</h6></a>
            <a href="https://athleticstanzania.blogspot.com/"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>Shirikisho la Riadha Tanzania</h6></a>
            <a href="https://olympics.com/ioc"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>International Olympic Committee - IOC</h6></a>
            <a href="https://worldathletics.org/"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>World Athletics - WA</h6></a>
            <a href="https://olympics.com/ioc/united-republic-of-tanzania"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>Tanzania Olympic Committee</h6></a>
            <a href="https://www.michezo.go.tz/"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>Ministry of Culture, Arts & Sports</h6></a>
            <a href="http://nationalsportscouncil.go.tz/"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>Baraza La Michezo Tanzania</h6></a>
            <a href="https://www.tanzaniatourism.go.tz"><h6 className="text-secondary font-regular" style={{ fontSize: "12px" }}>Bodi ya Utalii Tanzania</h6></a>
          </div>

          <div className="col-md-4"  style={{ marginBottom:"20px", fontSize: "12px" }}>
            <h4 className="font-bold">Disclaimer</h4>
            Run Tanzania Initiative<sup>&#9415;</sup> and its affiliates are striving for excellency,
            accuracy and trust. However, we do guarantee our esteemed subscribers and readers the validity of
            information and details provides on our sites by third parties though we accept no liabilities for any
            transactions made to them except to and through CAS & Media Co. Ltd verified bank accounts.
          </div>

          <div className="col-md-4" style={{ marginBottom:"20px" }}>
            <h4 className="font-bold">Main Office</h4>
            <h6>Monduli, Arusha</h6>
            <h6>Tanzania</h6>
          </div>

          <div className="col-md-12" style={{ marginBottom:"20px" }}>
            <hr/>
          </div>

          <div className="col-md-4" style={{ marginBottom:"20px" }}>
            <h4 className="font-bold">Support</h4>
            support@runtanzania.org<br/>
            +255 687 887 222
          </div>

          <div className="col-md-4" style={{ marginBottom:"20px" }}>
            <h4 className="font-bold">Social</h4>
            <a target="_blank" href="#" style={{ color: "white" }}><BsInstagram size={30} style={{ marginRight: "20px" }}/></a>
            <a target="_blank" href="#" style={{ color: "white" }}><BsFacebook size={30} style={{ marginRight: "20px" }}/></a>
            <a target="_blank" href="#" style={{ color: "white" }}><BsYoutube size={30} style={{ marginRight: "20px" }}/></a>
          </div>

          

          <div className="col-md-4" style={{ marginBottom:"100px" }}>
            <h5 className="font-bold">&copy;RUNTanzania Initiative<sup>&#9415;</sup></h5>
          </div>

        </div>
        
        
      </div>
      
    </div>

    
  )
}