import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import {
  MdPostAdd
} from 'react-icons/md';
import { FaDonate } from 'react-icons/fa';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [  scrollPosition, setScrollPosition ] = useState(0);

  function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(scrollPosition > 0) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  return (
    <div className="Section bg-background" >
      
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ marginTop: "0px" }}>
            <h4 className="font-bold">Admin Dashboard</h4>
          </div>

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "dashboard", subItem: "post_event" })
              }}
            >
              <MdPostAdd size={50}/><br/>
              Post New Event
            </button>
          </div>

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "dashboard", subItem: "post_marketplace_item" })
              }}
            >
              <MdPostAdd size={50}/><br/>
              Post To Marketplace
            </button>
          </div>

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "dashboard", subItem: "create_poll" })
              }}
            >
              <MdPostAdd size={50}/><br/>
              Create Poll
            </button>
          </div>

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "dashboard", subItem: "all_donations" })
              }}
            >
              <FaDonate size={50}/><br/>
              All Donations
            </button>
          </div>

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "dashboard", subItem: "all_sponsorships" })
              }}
            >
              <FaDonate size={50}/><br/>
              All Sponsorships
            </button>
          </div>
          
        </div>
      </div>
      
    </div>

    
  )
}