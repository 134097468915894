import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import {
  MdPostAdd,
  MdOutlineHowToVote,
} from 'react-icons/md';
import { FaDonate } from 'react-icons/fa';
import { IoTicketOutline } from 'react-icons/io5';
import { callApi } from "../Helpers";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [  scrollPosition, setScrollPosition ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ verificationCode, setVerificationCode ] = useState("");

  function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
  }

  async function requestCode() {
    if(!loading) {
      setLoading(true);
      await callApi("request_verification_code.php", { }).then(response => {
        if(response.status === 1) {
          appContext.tellMessage('Verification code was sent');
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    }
  }

  async function verifyAccount() {
    if(!loading) {
      if(verificationCode.trim().length > 0) {
        setLoading(true);
        await callApi("verify_account.php", { verificationCode }).then(response => {
          if(response.status === 1) {
            appContext.tellMessage("Your account was verified");
            appContext.refresh();
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError("Invalid verification code");
      }
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(scrollPosition > 0) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  return (
    <div className="Section bg-background" >
      
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ marginTop: "0px" }}>
            <h4 className="font-bold">RUNTanzania Member Dashboard</h4>
          </div>

          {
            (appContext.userData.status === 'unverified') ?
            <div className="col-md-12" style={{ marginTop: "20px", marginBottom: "20px" }}>
              
              <h5 className="font-light text-danger">Verify your account to unlock all features</h5>

              <div
                className=""
                style={{
                  maxWidth: "400px",
                }}
              >
                <div className="form-group">
                  <label>Verification Code</label>
                  <input className="form-control" type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)}/>
                  <small className="text-muted" style={{ display: "block" }}>Code was sent to your email address: <span className="font-bold">{appContext.userData.email}</span></small>
                </div>

                <div className="text-end">
                  <button
                    className="btn bg-primary"
                    onClick={verifyAccount}
                  >
                    Verify
                  </button>
                </div>

                <div className="text-start">
                  Or <span onClick={requestCode} style={{ cursor: "pointer" }} className="font-bold">Request new code</span>
                </div>
              </div>
            </div>:""
          }

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "portal", subItem: "my_votes" })
              }}
            >
              <MdOutlineHowToVote size={50}/><br/>
              My Votes
            </button>
          </div>

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "dashboard", subItem: "my_donations" })
              }}
            >
              <FaDonate size={50}/><br/>
              My Donations
            </button>
          </div>

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "dashboard", subItem: "my_sponsorships" })
              }}
            >
              <FaDonate size={50}/><br/>
              My Sponsorships
            </button>
          </div>

          <div className="col-md-4" style={{ marginTop: "20px" }}>
            <button
              className="bg-dark btn text-start font-bold"
              style={{
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                fontSize: "16px",
              }}
              onClick={() => {
                appContext.navTo({ item: "dashboard", subItem: "my_tickets" })
              }}
            >
              <IoTicketOutline size={50}/><br/>
              My Tickets
            </button>
          </div>
          
        </div>
      </div>
      
    </div>

    
  )
}