import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import sponsor1 from '../assets/images/sponsor1.jpg';
import { TiTickOutline } from 'react-icons/ti';
import mpesa from '../assets/mpesa.png';
import tpesa from '../assets/tpesa.png';
import airtelmoney from '../assets/airtelmoney.png';
import tigopesa from '../assets/tigopesa.png';
import halopesa from '../assets/halopesa.png';
import ezypesa from '../assets/ezypesa.png';
import creditcards from '../assets/creditcards.png';
import { formatMoney, callApi } from "../Helpers";
import { 
  MdOutlineEventNote,
  MdOutlineFileDownload,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineApple,
  AiOutlineAndroid
} from 'react-icons/ai';
import { IoLogoGooglePlaystore } from 'react-icons/io5';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ loading, setLoading ] = useState(false);
  const [  scrollPosition, setScrollPosition ] = useState(0);
  const [ userData, setUserData ] = useState(null);
  const [ payWith, setPayWith ] = useState('');
  const [ payFinal, setPayFinal ] = useState(false);
  const [ payError, setPayError ] = useState(false);
  const [ payMessage, setPayMessage ] = useState(false);
  const [ amount, setAmount ] = useState('');
  const [ name, setName ] = useState('');
  const [ comment, setComment ] = useState('');
  const [ contactPhone, setContactPhone ] = useState('');
  const [ contactEmail, setContactEmail ] =useState('');
  const [ payPhone, setPayPhone ] = useState('');

  const resetPayment = () => {
    setPayFinal(false);
    setPayWith('');
    setPayError(false);
    setPayMessage(false);
  }

  const finalizeDonation = async () => {
    //this finalizes donation process and sends payment request to calipa API
    let _isOkay = true;
    if(payWith === 'mobile_money') {
      if(payPhone.trim().length === 10) {
        //good
      } else  {
        _isOkay = false;
        appContext.tellError('Invalid Phone Number')
      }
    }

    if(_isOkay) {
      await appContext.getLocalUser().then(async user => {
        if(user) {
          setLoading(true);
          await callApi(
            'submit_donation_request.php', 
            { 
              ...user, 
              name, 
              amount, 
              comment,
              phone: contactPhone, 
              email: contactEmail, 
              payWith,
              payPhone,
            }).then(response => {
              setLoading(false);
              if(response.status === 1) {
                if(payWith === 'credit_card') {
                  setPayMessage('New window will be opened for you to complete your payment');
                  window.location.href = response.payment_gateway_url;
                } else {
                  setPayMessage('Check your phone we sent a USSD push message for you to complete your payment')
                }
                setPayWith('');
                setPayFinal(true);
              } else {
                setPayError(response.msg);
                setPayWith('')
                setPayFinal(true);
              }
            })
        } else {
          appContext.tellError('Login please');
        }
      })
    }
    
  }

  const validateForm = (_payWith) => {
    return new Promise(resolve => {
      let amountLimit = (_payWith === 'mobile_money') ? 1000 : 3000;
      if(name.trim().length > 0) {
        if(amount.trim().length > 0 && !isNaN(amount) && Number(amount) >= amountLimit) {
          if(contactPhone.trim().length > 0) {
            if(contactEmail.trim().length > 0) {
              resolve(true);
            } else {
              appContext.tellError('Invalid contact person email');
              resolve(false);
            }
          } else {
            appContext.tellError('Invalid contact person phone');
            resolve(false);
          }
        } else {
          appContext.tellError('Invalid amount, minimum is '+amountLimit+' Tshs');
          resolve(false);
        }
      } else {
        appContext.tellError('Invalid name');
        resolve(false);
      }
    })
  }

  const payWithMobileMoney = () => {
    validateForm('mobile_money').then(result => {
      if(result) {
        setPayWith('mobile_money');
      }
    })
  }

  const payWithCreditCard = () => {
    validateForm('credit_card').then(result => {
      if(result) {
        setPayWith('credit_card');
      }
    })
  }


  function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    if(appContext.auth && appContext.userData) {
      setUserData(appContext.userData);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(userData) {
      setContactEmail(userData.email);
    }
  }, [ userData ])

  useEffect(() => {
    if(scrollPosition > 0 && !props.isHome) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row">
          <div className="col-md-12">
            <h1 className="font-bold">Donate to us</h1>
          </div>


          <div className="col-md-12">
            {
              (payWith === '' && !payFinal) ?
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Your Name (Organization or company name)</label>
                    <input className="form-control" value={name} type="text" onChange={(e) => setName(e.target.value)}/>
                  </div>

                  <div className="form-group">
                    <label>Amount in Tsh</label>
                    <input className="form-control" value={amount} type="number" onChange={(e) => setAmount(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Comment <span className="text-danger" >**Optional</span></label>
                    <textarea className="form-control" value={comment} onChange={(e) => setComment(e.target.value)} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Contact Person's Phone Number</label>
                    <input className="form-control" value={contactPhone} type="text" onChange={(e) => setContactPhone(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Contact Person's Email address</label>
                    {
                      (userData) ?
                      <input value={contactEmail} readOnly={true} className="form-control" type="email" />
                      :
                      <input value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} className="form-control" type="email" />
                    }
                  </div>
                </div>

                <div className="col-md-12">
                  <button 
                    className="btn bg-primary font-light"
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "20px",
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}
                    onClick={payWithMobileMoney}
                  >
                    Donate using Mobile Money
                  </button>

                  <button 
                    className="btn bg-dark font-light"
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "20px",
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}
                    onClick={payWithCreditCard}
                  >
                    Donate using Credit Card
                  </button>
                </div>

              </div> : ""
            }

            {
              (payWith === 'mobile_money') ?
              <div className="row">
                
                <div className="col-md-6">
                  <div className="form-group text-start">
                    <img src={mpesa}  style={{ display: "inline", width: "50px", height: "auto" }}/>
                    <img src={airtelmoney}  style={{ display: "inline", width: "50px", height: "auto" }}/>
                    <img src={halopesa}  style={{ display: "inline", width: "50px", height: "auto" }}/>
                    <img src={tigopesa}  style={{ display: "inline", width: "50px", height: "auto" }}/>
                    <img src={ezypesa}  style={{ display: "inline", width: "50px", height: "auto" }}/>
                    <img src={tpesa}  style={{ display: "inline", width: "50px", height: "auto" }}/>
                  </div>
                  <h5 className="font-bold text-primary">Donate Using Mobile Money Wallet</h5>
                  <button
                    className="btn bg-dark btn-sm"
                    style={{
                      
                    }}
                    onClick={() => setPayWith('credit_card')}
                  >
                    Use Credit Card Instead
                  </button>

                  <h1 className="font-light" style={{ marginTop: "40px", fontSize: "40px" }}>{formatMoney(amount)}</h1>
                  <h4 className="font-light">Tsh</h4>

                  <div className="form-group">
                    <label>Phone Number (All Tanzanian Networks)</label>
                    <input value={payPhone} onChange={(e) => setPayPhone(e.target.value) } className="form-control" maxLength={10} type="text" placeholder="07XXXXXXXX" />
                    <small>Phone number that'll be used to make this payment. Ten digits without country code eg 07XXXXXXXX or 06XXXXXXXX</small>
                  </div>

                  <div className="form-group">
                    <button 
                      className="btn bg-primary font-light"
                      onClick={finalizeDonation}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "20px",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>

              </div> : ""
            }

            {
              (payWith === 'credit_card') ?
              <div className="row">
                
                <div className="col-md-6">
                  <div className="form-group text-center">
                    <img src={creditcards}  style={{ display: "block", width: "100%", height: "auto" }}/>
                    
                  </div>
                  <h5 className="font-bold text-primary">Donate Using Credit Card</h5>
                  <button
                    className="btn bg-dark btn-sm"
                    style={{
                      
                    }}
                    onClick={() => setPayWith('mobile_money')}
                  >
                    Use Mobile Money Instead
                  </button>

                  <h1 className="font-light" style={{ marginTop: "40px", fontSize: "40px" }}>{formatMoney(amount)}</h1>
                  <h4 className="font-light">Tsh</h4>

                  <div className="form-group">
                    <button 
                      className="btn bg-primary font-light"
                      onClick={finalizeDonation}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "20px",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>

              </div> : ""
            }

            {
              (payFinal) ?
              <div className="row">
                <div className="col-md-12 text-center">
                  {
                    (payMessage) ?
                    <h5 className="font-light text-primary">{payMessage}</h5>
                    : ""
                  }

                  {
                    (payError) ?
                    <h5 className="font-light text-danger">{payError}</h5>
                    : ""
                  }
                  <hr/>
                  <div className="form-group">
                    <button 
                      className="btn bg-primary font-light"
                      style={{
                        padding: "10px",
                        fontSize: "16px",
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        appContext.navTo({
                          item: 'home',
                          subItem: 'support-us',
                        })
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              </div>: ""
            }
            
          </div>
        </div>

        
      </div>
      
    </div>

    
  )
}