import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdSearch,
} from 'react-icons/md';
import {
  AiOutlineMessage,
  AiOutlineSwapRight
} from 'react-icons/ai';
import PollThumbnail from '../ones/PollThumbnail';
import { callApi, formatDate, formatMoney, getInlineLoader } from "../Helpers";


export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ allTickets, setAllTickets ] = useState([]);
  const [ scrollPosition, setScrollPosition ] = useState(0);

  

  async function getAllTickets() {
    await callApi('get_my_tickets.php', { }).then(response => {
      if(response.status === 1) {
        setAllTickets(response.data);
      } 
    })
  }

  function viewTicket(ticketId) {
    appContext.navTo({
      item: 'view',
      subItem: 'ticket',
      extraItem: ticketId,
    })
  }

  async function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getAllTickets();
    setReady(true);
  } 

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(scrollPosition > 0) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])


  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row" >
          <div className="col-md-12">
            <h1 className="font-bold">My Tickets</h1>
            <h6 className="font-light text-muted">View your tickets for various events here</h6>
          </div>

          <div className="col-md-12 text-end" style={{ marginBottom: "20px" }}>
            <button
              className="btn btn-sm font-bold"
              onClick={() => {
                appContext.navTo({
                  item: 'home',
                  subItem: 'events',
                })
              }}
            >
              More Events <AiOutlineSwapRight size={20}/>
            </button>
          </div>

          {
            (ready) ?
            <>
              {
                (allTickets && allTickets.length > 0) ?
                allTickets.map((item, i) => {
                  return (
                    <div className="col-md-12" key={i} style={{ marginBottom: "20px" }}>
                      <div
                        className="text-start mShadow3"
                        style={{  width: "100%", padding: "10px" }}
                      >
                        <h5 className="font-bold text-muted">{item.name}</h5>
                        <h4>{formatMoney(item.price)} Tsh</h4>

                        <h6 className="font-light" style={{ marginTop: "20px", marginBottom: "20px" }}>
                          <span style={{ fontSize: "12px" }} className="font-bold text-muted">EVENT</span><br/>
                          <span className="font-bold">{item.eventData.title}</span>
                        </h6>

                        {
                          (item.status === 'verified') ?
                          <h6 className="font-light" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <span style={{ fontSize: "12px" }} className="font-bold text-muted">STATUS</span><br/>
                            <span className="text-success font-medium">{item.status}</span>
                            <br/><br/>
                            <span style={{ fontSize: "12px" }} className="font-bold text-muted">EXPIRED</span><br/>
                            <span className="font-medium" style={{ textTransform: "capitalize" }}>{(item.expired === 'yes') ? <>{item.expired} on {formatDate(item.expiredDate)}</> : item.expired }</span>
                            <br/><br/>
                            <span style={{ fontSize: "12px" }} className="font-bold text-muted">Ticket ID</span><br/>
                            <span className="text-success font-medium">{item.transactionId}</span><br/><br/>
                            <button onClick={() => viewTicket(item.transactionId)} className="btn btn-sm bg-primary">VIEW or PRINT</button>
                          </h6>
                          :
                          <h6 className="font-light" style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <span style={{ fontSize: "12px" }} className="font-bold text-muted">STATUS</span><br/>
                            <span className="text-danger font-medium">{item.status}</span>
                          </h6>
                        }

                        <h6 className="text-end font-medium text-muted">{item.date}</h6>
                      </div>
                      
                    </div>
                  )
                })
                :
                <h6 className="font-light">No tickets were found</h6>
              }
            </>
            :
            <div className="col-md-12 mSupportLoading">
              {getInlineLoader()}
            </div>
          }

          
        </div>
        
      </div>
      
    </div>

    
  )
}