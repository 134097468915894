import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
  MdOutlineShare
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';
import { IoTicketOutline } from 'react-icons/io5';
import { BASE_API, callApi, copyText, formatDate, formatMoney, getInlineLoader } from "../Helpers";

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ data, setData ] = useState(null);
  const [ id, setId ] = useState(props.id);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [  scrollPosition, setScrollPosition ] = useState(0);
  const [ showBuyForm, setShowBuyForm ] = useState(false);
  const [ selectedTicket, setSelectedTicket ] = useState(null);
  const [ selectedTicketIndex, setSelectedTicketIndex ] = useState(-1);
  const [ payWith, setPayWith ] = useState(false);
  const [ payPhone, setPayPhone ] = useState("");
  const [ payError, setPayError ] = useState(false);
  const [ payFinalMessage, setPayFinalMessage ] = useState(false);

  async function getEventData() {
    await callApi("get_event_data.php", { eventId: id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function buyTicketConfirm() {
    //..
    if(appContext.auth && appContext.userData) {
      //..
      if(selectedTicket) {
        if(payWith && payWith.trim().length > 0) {
          if((payWith === 'mobile_money' && payPhone.trim().length === 10) || payWith === 'credit_card') {
            //let's dance
            setLoading(true);
            await callApi("buy_ticket.php", {
              ...selectedTicket,
              eventId: id,
              payWith,
              payPhone,
            }).then((response) => {
              if(response.status === 1) {
                appContext.tellMessage("Payment process has been initiated, check your phone or wait for redirection if you selected credit card");
                setPayFinalMessage('Payment process has been initiated, check your phone or wait for redirection if you selected credit card');
              } else {
                appContext.tellError(response.msg)
                setPayError(response.msg);
              }
            })
            setLoading(false);
          } else {
            appContext.tellError("Invalid phone number");
          }
        } else {
          appContext.tellError("Please payment method");
        }
      } else {
        appContext.tellError("Please select a ticket first");
      }
      //..
    } else {
      appContext.tellError("Please login or register first");
      appContext.navTo({
        item: 'portal',
      })
    }
    //..
    
  }

  function buyTicketInit(ticket, index) {
    setShowBuyForm(true);
    setPayWith(false);
    setPayPhone("");
    setSelectedTicket(ticket);
    setSelectedTicketIndex(index);
    setPayError(false);
    setPayFinalMessage(false);
  }

  async function copyLink() {
    appContext.tellMessage("Event link was copied")
    await copyText("https://runtanzania.org/#/event/"+data.uid);
  }

  async function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
    setReady(false);
    await getEventData();
    setReady(true);
  }

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    init();
  }, [ ])

  useEffect(() => {
    if(scrollPosition > 0) {
      //appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  if(ready) {
    if(data) {
      return (
        <div className="Section bg-background">
          <div className="container">
            <div className="row">
              
              <div className="col-md-12">
                <img src={BASE_API+data.coverPhoto} width="100%" height="auto"/>
              </div>

              <div className="col-md-12">
                <h1
                  className="font-bold"
                  style={{
                    fontSize: "50px"
                  }}
                >
                  {data.title}
                </h1>

                <div className="text-end" style={{ marginTop: "30px" }}>
                  {
                    ((new Date(data.startDate * 1000)).toString().substring(0,15) === (new Date(data.endDate * 1000)).toString().substring(0,15)) ?
                    <h6>{(new Date(data.startDate * 1000)).toString().substring(0,15)}</h6>
                    :
                    <h6><span className="font-bold">From </span>{(new Date(data.startDate * 1000)).toString().substring(0,15)} <span className="font-bold">to</span> {(new Date(data.endDate * 1000)).toString().substring(0,15)}</h6>
                  }

                  <button onClick={copyLink} className="btn btn-sm text-primary mShadow3 font-bold" style={{ margin: "5px" }}><MdOutlineShare size={20}/> Copy Link</button>
                  
                  {
                    (JSON.parse(data.tickets).length > 0) ?
                    <button onClick={() => document.getElementById('_tickets').scrollIntoView({ behavior: "smooth" })} className="btn btn-sm text-primary mShadow3 font-bold" style={{ margin: "5px" }}><IoTicketOutline size={20}/> Buy Ticket</button>
                    :""
                  }
                </div>
                <hr/>
              </div>

              <div className="col-md-12">
                <div
                  dangerouslySetInnerHTML={{__html: data.description}}
                  style={{ 
                    maxWidth:  "992px",
                    margin: "0 auto"
                  }}
                >
                </div>
                <hr/>
              </div>

              {
                (JSON.parse(data.tickets).length > 0) ?
                <div className="col-md-12" id="_tickets">

                  <h4 style={{ marginBottom: "30px", marginTop: "30px" }} className="text-center">
                    <IoTicketOutline size={70} className="text-primary"/><br/>
                    Buy Your Ticket Now!
                  </h4>
                  <div className="row">
                    {
                      JSON.parse(data.tickets).map((item, i) => {
                        return (
                          <div className="col-md-6" key={i}>
                            <div className="mShadow3" style={{ width: "100%", padding: "10px", borderRadius: "10px", marginBottom: "20px" }}>
                              <h5 className="font-bold">{item.name}</h5>
                              <h6 className="font-light">{item.description}</h6>
                              <h2>{formatMoney(item.price)} <span style={{ fontSize: "14px" }} className="font-bold">Tsh</span></h2>
                              {
                                (showBuyForm && selectedTicketIndex === i) ?
                                <>
                                {
                                  (payWith && payWith.trim().length > 0) ?
                                  <>
                                    {
                                      (payWith === 'mobile_money' && !payError && !payFinalMessage) ?
                                      <div 
                                        className=""
                                        style={{ width: "100%" }}
                                      >
                                        <hr/>
                                        <div className="form-group">
                                          <label>Phone Number</label>
                                          <small className="text-muted text-medium" style={{ display: "block" }}>10 digit number. Only Tanzanian mobile networks</small>
                                          <input className="form-control" type="text" maxLength={10} value={payPhone} onChange={(e) => setPayPhone(e.target.value)}/>
                                        </div>
                                        <button 
                                          className="btn btn-block bg-primary"
                                          style={{ marginBottom: "20px", fontSize: "20px" }}
                                          onClick={buyTicketConfirm}
                                        >
                                          Confirm & Pay
                                        </button>

                                        <h6>Or <span style={{ cursor: "pointer" }} onClick={() => setPayWith('credit_card')} className="font-bold ">click here to use credit card</span> instead</h6>
                                      </div> : ""
                                    }
                                    
                                    {
                                      (payWith === 'credit_card' && !payError && !payFinalMessage) ?
                                      <div 
                                        className=""
                                        style={{ width: "100%" }}
                                      >
                                        <hr/>
                                        <div className="form-group">
                                          <h6>Click 'Confirm & Pay' then you will be redirected to MasterCard/VisaCard/Express payment gateway</h6>
                                        </div>
                                        <button 
                                          className="btn btn-block bg-primary"
                                          style={{ marginBottom: "20px", fontSize: "20px" }}
                                          onClick={buyTicketConfirm}
                                        >
                                          Confirm & Pay
                                        </button>

                                        <h6>Or <span style={{ cursor: "pointer" }} onClick={() => setPayWith('mobile_money')} className="font-bold ">click here to use mobile money</span> instead</h6>
                                      </div> : ""
                                    }

                                    {
                                      (payFinalMessage) ?
                                      <div 
                                        className="text-center"
                                        style={{ width: "100%" }}
                                      >
                                        <hr/>
                                        <h6 className="text-success font-medium">{payFinalMessage}</h6>
                                        <button
                                          style={{
                                            marginTop: "10px"
                                          }}
                                          className="btn bg-dark"
                                          onClick={() => {
                                            setSelectedTicket(null);
                                            setSelectedTicketIndex(-1);
                                            setPayWith(false);
                                            setPayError(false);
                                            setPayFinalMessage(false);
                                          }}
                                        >
                                          Try Again
                                        </button>
                                      </div> : ""
                                    }

                                    {
                                      (payError) ?
                                      <div 
                                        className="text-center"
                                        style={{ width: "100%" }}
                                      >
                                        <hr/>
                                        <h6 className="text-danger font-medium">{payError}</h6>
                                        <button
                                          style={{
                                            marginTop: "10px"
                                          }}
                                          className="btn bg-dark"
                                          onClick={() => {
                                            setSelectedTicket(null);
                                            setSelectedTicketIndex(-1);
                                            setPayWith(false);
                                            setPayError(false);
                                            setPayFinalMessage(false);
                                          }}
                                        >
                                          Try Again
                                        </button>
                                      </div> : ""
                                    }
                                  </>
                                  :
                                  <div 
                                    className=""
                                    style={{ width: "100%" }}
                                  >
                                    <hr/>
                                    <h5 style={{ marginBottom: "20px" }} className="text-center text-muted font-bold">Pay with?</h5>
                                    
                                    <button 
                                      className="btn btn-block bg-dark"
                                      style={{ marginBottom: "20px" }}
                                      onClick={() => setPayWith("mobile_money")}
                                    >
                                      Mobile Money
                                    </button>

                                    <button 
                                      className="btn btn-block btn-dark"
                                      style={{ marginBottom: "20px" }}
                                      onClick={() => setPayWith("credit_card")}
                                    >
                                      Credit Card
                                    </button>
                                  </div>
                                }
                                </>
                                :
                                <button
                                  className="btn bg-primary btn-block"
                                  style={{ fontSize: "20px" }}
                                  onClick={() => buyTicketInit(item, i)}
                                >
                                  BUY
                                </button>
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  
                </div>
                :""
              }

              

            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="Section bg-background">
          <div className="mSupportLoading" style={{ width: "100%" }}>
            {getInlineLoader()}
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className="Section bg-background">
        <div className="mSupportLoading" style={{ width: "100%" }}>
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}