import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import logo from '../assets/logo.png';
import { TiTickOutline } from 'react-icons/ti';
import { 
  MdOutlineEventNote,
} from 'react-icons/md';
import {
  AiOutlineMessage
} from 'react-icons/ai';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [  scrollPosition, setScrollPosition ] = useState(0);

  function init() {
    //get current position
    setScrollPosition(appContext.getCurrentScrollPosition());
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(scrollPosition > 0 && !props.isHome) {
      appContext.scrollToTop();
    }
  }, [ scrollPosition ])

  return (
    <div className="Section bg-background" >
      
      <div className="container">

        <div className="row">
          <div className="col-md-12">
            <h1 className="font-bold">About</h1>
            <span className="font-bold">Run TANZANIA INITIATIVE&reg;</span> chini ya <span className="font-bold">CAS & Media Co. Ltd </span> 
            ni jukwaa huru la kuibua, kuendeleza na kukuza Michezo, Wanamichezo, Utalii na Biashara zinazohusiana na hayo. 
            Pia tunahamasisha mitindo bora ya maisha kwa kuandaa, kusimamia, na/au kutangaza matukio, matamasha na maonesho na kutoa zawadi au tuzokwa wadau wanaofanya vizuri zaidi katika nyanja husika.
            <br/><br/>
            Kwenye jukwaa hili utaweza kununua tiketi za matamasha mbalimbali, kununua bidhaa au huduma, kutangaza biashara, kushiriki kwenye mashindano mbalimbali pamoja na kupiga kura kwa ajili ya tuzo mbalimbali.
          </div>
        </div>

        
      </div>
      
    </div>

    
  )
}